// if this works we may want to move this to the cms for easier configuration
const baseAdPlacementConfig = {
  desktopAdFrequency: 6,
  firstAdPlacement: 3,
  mobileAdFrequency: 4,
  showBetweenLatestsAndPopular: false,
  showEndArticleAd: false,
  showLeaderboardAd: true,
};
const LeadershipAdPlacementConfig = {
  desktopAdFrequency: 6,
  firstAdPlacement: 3,
  mobileAdFrequency: 4,
  showBetweenLatestsAndPopular: false,
  showEndArticleAd: false,
  showLeaderboardAd: true,
};

interface AdPlacementConfigProps {
  desktopAdFrequency: number;
  mobileAdFrequency: number;
  showBetweenLatestsAndPopular: boolean;
  showEndArticleAd: boolean;
  showLeaderboardAd: boolean;
}

const getAdPlacementConfig = (section: string[]) => {
  if (section.includes('Leadership') || section.includes('Success')) {
    return LeadershipAdPlacementConfig;
  }
  return baseAdPlacementConfig;
};
export default getAdPlacementConfig;
export type { AdPlacementConfigProps };
